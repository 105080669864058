import type { ReactNode } from "react";
import { t } from "@/i18n-js/instance";
import { Icon } from "@circle-react-shared/Icon";

const memberBadgeClassName = "member-badge__name";

interface ContentProps {
  label?: string;
  icon?: ReactNode;
  closeIconAriaLabel?: string;
  onClose?: () => void;
}

export const Content = ({
  label,
  icon,
  closeIconAriaLabel = t("remove"),
  onClose,
}: ContentProps) => (
  <>
    {icon}
    <span className={memberBadgeClassName}>{label}</span>
    {onClose && (
      <button
        type="button"
        className="ml-0.5 inline-flex h-4 shrink-0 items-center justify-center text-gray-500 focus:text-white"
        onClick={onClose}
        aria-label={closeIconAriaLabel}
      >
        <Icon type="close" />
      </button>
    )}
  </>
);
