import type { JSXElementConstructor, ReactNode } from "react";
import { Children, Fragment, cloneElement, isValidElement } from "react";
import classnames from "classnames";
import { get } from "lodash";
import type { RegisterOptions } from "react-hook-form";
import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import type { ItemLabelProps } from "./ItemLabel";
import { ItemLabel } from "./ItemLabel";

export interface ChildrenProps {
  id?: string;
  name?: string;
  placeholder?: string;
  required?: boolean;
  rules?: RegisterOptions;
  transformValue?: (value: any) => any;
}

export interface ChildrenWrapperProps {
  children: ReactNode;
}

export interface ItemProps extends ItemLabelProps {
  addFormGroupClass?: boolean;
  children?: ReactNode;
  childrenWrapper?: JSXElementConstructor<ChildrenWrapperProps>;
  className?: string;
  errorClassName?: string;
  fullWidth?: boolean;
  hiddenField?: boolean;
  hideBorder?: boolean;
  hideError?: boolean;
  hideLabel?: boolean;
  hidePlaceholder?: boolean;
  inline?: boolean;
  inlineReverse?: boolean;
  isFirstChild?: boolean;
  isToggle?: boolean;
  placeholder?: string;
  prefix?: ReactNode;
  forceRequiredLabel?: boolean;
  resizeDisabled?: boolean;
  rules?: RegisterOptions;
  transformValue?: (value: any) => any;
  translationRoot?: string;
  twoColumn?: boolean;
}

export const Item = ({
  addFormGroupClass = true,
  children,
  childrenWrapper,
  className,
  description,
  emptyLabel = false,
  errorClassName = "form-group__error",
  fullWidth = false,
  hiddenField = false,
  hideBorder = false,
  hideDescription = false,
  hideError = false,
  hideLabel = false,
  hidePlaceholder = false,
  id,
  inline,
  inlineReverse,
  isDisabled = false,
  isFirstChild = false,
  isToggle = false,
  label,
  labelClassName,
  labelWrapperClassName,
  name = "",
  placeholder = "",
  prefix,
  required = false,
  forceRequiredLabel = false,
  resizeDisabled = false,
  rules,
  shouldToggleValueOnLabelClick,
  onToggleValue,
  tooltipText = "",
  tooltipTextClassName = "",
  transformValue,
  translationRoot,
  twoColumn = false,
  variant = "default",
}: ItemProps) => {
  const localLabel =
    label || (translationRoot && t(`${translationRoot}.${name}`));

  const localDescription =
    description || t(`${translationRoot}.${name}_description`);

  let localPlaceHolder: string | undefined = undefined;

  if (!hidePlaceholder) {
    localPlaceHolder =
      placeholder ||
      (translationRoot && t(`${translationRoot}.${name}_placeholder`));
  }

  const {
    formState: { errors },
  } = useFormContext();

  const error = get(errors, name);
  const ChildrenWrapper = childrenWrapper || Fragment;

  const hasRequiredRule = Boolean(
    rules && typeof rules.required === "boolean" && rules.required,
  );

  return (
    <div
      className={classnames(
        "",
        {
          "form-group": !hiddenField && addFormGroupClass,
          "no-border": hideBorder,
          "with-prefix form-group--prefix": prefix,
          "form-group--column w-full": fullWidth,
          "form-group--two-columns": twoColumn,
          "form-group--toggle": isToggle,
          "form-group--inline": inline,
          "form-group--inline-reverse": inlineReverse,
          "form-group--has-error": error,
          "form-group--resize-disabled": resizeDisabled,
          "no-padding-top": isFirstChild,
        },
        className,
      )}
    >
      {!hideLabel && (
        <ItemLabel
          description={localDescription}
          emptyLabel={emptyLabel}
          hideDescription={hideDescription}
          id={id}
          isDisabled={isDisabled}
          label={localLabel}
          labelClassName={labelClassName}
          labelWrapperClassName={labelWrapperClassName}
          name={name}
          required={required || hasRequiredRule || forceRequiredLabel}
          shouldToggleValueOnLabelClick={shouldToggleValueOnLabelClick}
          onToggleValue={onToggleValue}
          tooltipText={tooltipText}
          tooltipTextClassName={tooltipTextClassName}
          variant={variant}
        />
      )}

      {prefix && <div className="form-prepend-content">{prefix}</div>}

      <ChildrenWrapper>
        {Children.map(children, child => {
          if (!isValidElement<ChildrenProps>(child)) {
            return;
          }

          return cloneElement(child, {
            name,
            rules,
            id,
            placeholder: localPlaceHolder,
            transformValue,
            required: required || hasRequiredRule,
          });
        })}
        {error && !hideError && (
          <p className={errorClassName}>{error.message}</p>
        )}
      </ChildrenWrapper>
    </div>
  );
};

Item.displayName = "Form.Item";
