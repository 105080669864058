import type {
  BaseSyntheticEvent,
  ComponentPropsWithoutRef,
  MutableRefObject,
  ReactNode,
} from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";
import isEmpty from "lodash/isEmpty";
import { FormProvider, useForm, useFormState } from "react-hook-form";
import type {
  DeepPartial,
  SubmitHandler,
  UseFormReset,
  UseFormSetError,
} from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { CSRFTokenField } from "@circle-react-uikit/CSRFTokenField";
import { PreventAccidentalLeave } from "./PreventAccidentalLeave";
import { dirtyValues } from "./utils";
import "./styles.scss";

type NativeFormProps = Omit<
  ComponentPropsWithoutRef<"form">,
  "onSubmit" | "defaultValue"
>;

export type OnSubmitFunction<FormValues extends Record<string, any>> = (
  values: FormValues,
  helpers: {
    reset: UseFormReset<FormValues>;
    setError: UseFormSetError<FormValues>;
  },
  event?: BaseSyntheticEvent,
) => Promise<void> | void;

export interface FormProps<FormValues extends Record<string, any>>
  extends NativeFormProps {
  id?: string;
  triggerFormSubmitRef?: MutableRefObject<(() => Promise<void>) | null>;
  mode?: "onBlur" | "onChange" | "onSubmit" | "onTouched" | "all";
  children: ReactNode | ((props: any) => ReactNode);
  onSubmit: OnSubmitFunction<FormValues>;
  className?: string;
  formClassName?: string;
  preventAccidentalLeave?: boolean;
  preventAccidentalLeaveMessage?: string;
  defaultValues?: DeepPartial<FormValues>;
  validationSchema?: any;
  submitDirtyOnly?: boolean;
  shouldStopPropagation?: boolean;
  debug?: boolean;
}

export const Form = <FormValues extends Record<string, any>>({
  onSubmit,
  defaultValues,
  children,
  submitDirtyOnly = false,
  preventAccidentalLeave = false,
  preventAccidentalLeaveMessage = t("prevent_accidental_leave_message"),
  validationSchema,
  className,
  formClassName,
  mode = "onSubmit",
  id,
  triggerFormSubmitRef,
  shouldStopPropagation = false,
  ...passThroughProps
}: FormProps<FormValues>) => {
  const methods = useForm<FormValues>({
    mode,
    reValidateMode: "onChange",
    defaultValues,
    resolver: validationSchema ? yupResolver(validationSchema) : undefined,
  });

  const { reset, setError } = methods;
  const { dirtyFields } = useFormState({ control: methods.control });

  const _onSubmit: SubmitHandler<any> = async (params, event) => {
    const submitData = submitDirtyOnly
      ? dirtyValues(dirtyFields, params)
      : params;

    if (isEmpty(submitData)) {
      return;
    }

    await onSubmit(submitData, { reset, setError }, event);
  };

  const triggerFormSubmit = methods.handleSubmit(_onSubmit);

  if (triggerFormSubmitRef) {
    triggerFormSubmitRef.current = triggerFormSubmit;
  }

  const formProviderProps = { ...methods, triggerFormSubmit };

  const renderChildren = () =>
    typeof children === "function" ? children(formProviderProps) : children;

  return (
    <div className={classNames("react-form", className)}>
      <FormProvider {...formProviderProps}>
        {preventAccidentalLeave && (
          <PreventAccidentalLeave message={preventAccidentalLeaveMessage} />
        )}

        <form
          {...passThroughProps}
          id={id}
          className={formClassName}
          onSubmit={event => {
            if (shouldStopPropagation) {
              event.stopPropagation();
            }

            void triggerFormSubmit(event);
          }}
        >
          <CSRFTokenField />
          {renderChildren()}
        </form>
      </FormProvider>
    </div>
  );
};
